import { useMutation } from '@tanstack/react-query';
import { apiClient } from '../http/query-client';
import { useTrans } from '@ui/i18n/use-trans';
import { BackendResponse } from '../http/backend-response/backend-response';
import { toast } from '@ui/toast/toast';
import { message } from '@ui/i18n/message';
import { UseFormReturn } from 'react-hook-form';
import { onFormQueryError } from '../errors/on-form-query-error';
import { useNavigate } from '../ui/navigation/use-navigate';

interface Response extends BackendResponse {
  link?: string;
}

export interface ContactSuggestPayload {
  name: string;
  link: string;
  email: string;
  message: string;
}

export function useSubmitContactForm(form: UseFormReturn<ContactSuggestPayload>) {
  const { trans } = useTrans();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: (props: ContactSuggestPayload) => submitContactForm(props),
    onSuccess: (data) => {
      toast(trans(message(`Thank you for your support. We will review your request within 24 hours ☺️`))); // Mostra un avviso con il link inviato
      navigate('/');
    },
    onError: (err) => {
      if (err.message === "Impossibile inviare: il link non è valido.") {
        toast.danger(trans(message('The link must contain Spotify or YouTube link'))); // Messaggio aggiornato
      } else {
        onFormQueryError(err, form);
      }
    },
  });

  return mutation;
}

function submitContactForm(payload: ContactSuggestPayload): Promise<Response> {
  if (!payload.email) {
    payload.email = 'info@cristianify.it';
  }

  if (!payload.message) {
    payload.message = 'Ciao, vorrei consigliarvi un artista interessante da caricare nella vostra app';
  }

  // Validazione del link (accetta qualsiasi link che contenga spotify.com, youtube.com o music.youtube.com)
  if (!isValidLink(payload.link)) {
    return Promise.reject(new Error("Impossibile inviare: il link non è valido.")); // Rifiuta la richiesta se il link non è valido
  }

  payload.message += `\n\n\n\nLink: ${payload.link}`; // Aggiunge il link alla fine del messaggio

  return apiClient.post('contact-page', payload)
    .then(r => {
      console.log("Risultato della richiesta:", r.data);
      return { ...r.data, link: payload.link }; // Aggiunge il link alla risposta
    })
    .catch(error => {
      console.error("Errore durante la richiesta:", error);
      if (error.response) {
        console.error("Dettagli dell'errore:", error.response.status, error.response.statusText, error.response.data);
      }
      throw error;
    });
}

// Funzione di validazione del link (accetta qualsiasi link che contiene spotify.com, youtube.com o music.youtube.com)
function isValidLink(link: string): boolean {
  // Verifica se il link contiene spotify.com, youtube.com o music.youtube.com
  return /spotify\.com/.test(link) || /youtube\.com/.test(link) || /music\.youtube\.com/.test(link) || /spotify\.it/.test(link) || /youtube\.it/.test(link) || /music\.youtube\.it/.test(link);
}
