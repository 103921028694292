import { useMutation } from '@tanstack/react-query';
import { apiClient } from '../http/query-client';
import { useTrans } from '@ui/i18n/use-trans';
import { BackendResponse } from '../http/backend-response/backend-response';
import { toast } from '@ui/toast/toast';
import { message } from '@ui/i18n/message';
import { UseFormReturn } from 'react-hook-form';
import { onFormQueryError } from '../errors/on-form-query-error';
import { useNavigate } from '../ui/navigation/use-navigate';

// Supponiamo che questa funzione ritorni l'utente loggato (simulato in questo esempio)
function useAuth() {
  return { name: 'John Doe', email: 'john.doe@example.com' }; // Qui dovresti usare il sistema che hai per recuperare l'utente loggato
}

export interface ImportSpotifyPlaylistPayload {
  name: string;
  import_playlist_spotify: string;
  email: string;
  message: string;
}

export function useSubmitContactForm(form: UseFormReturn<ImportSpotifyPlaylistPayload>) {
  const { trans } = useTrans();
  const navigate = useNavigate();
  const auth = useAuth();  // Ottieni l'utente loggato

  const mutation = useMutation({
    mutationFn: (props: ImportSpotifyPlaylistPayload) => submitContactForm(props),
    onSuccess: () => {
      toast(trans(message('Thank you, we will review your request 😄'))); // Mostra un avviso nella pagina quando il messaggio viene inviato con successo
      navigate('/');
    },
    onError: (err) => {
      // Se l'errore è dovuto al link non valido, visualizza un avviso direttamente nella pagina
      if (err.message === "Impossibile inviare: il link non è valido.") {
        toast(trans(message('The link must be a valid Spotify playlist link.')), { type: 'danger' }); // Messaggio aggiornato
      } else {
        // Altrimenti, gestisci l'errore come al solito
        onFormQueryError(err, form);
      }
    },
  });

  // Ritorna la mutazione per poterla utilizzare nell'elemento di invio del modulo
  return mutation;
}

function submitContactForm(payload: ImportSpotifyPlaylistPayload): Promise<any> {
  const { name, email } = useAuth(); // Ottieni l'utente loggato qui

  // Imposta il nome dell'utente loggato nel payload se non è già presente
  if (!payload.name) {
    payload.name = name || 'Un utente di Cristianify'; // Usa il nome dell'utente loggato, se disponibile
  }

  // Imposta l'email dell'utente loggato nel payload
  if (!payload.email) {
    payload.email = email || 'info@cristianify.it'; // Usa l'email dell'utente loggato, se disponibile
  }

  // Imposta un messaggio fittizio se il campo message non è presente nel payload
  if (!payload.message) {
    payload.message = 'Ho bisogno di importare la mia playlist di spotify, questo è il link: ';
  }

  // Verifica se il link è valido (deve contenere "spotify.it", "spotify.com" o "open.spotify.com" e "playlist")
  const isValidLink = /^(https?:\/\/)?(www\.)?(spotify\.(it|com)|open\.spotify\.com)\/playlist\//.test(payload.import_playlist_spotify);
  if (!isValidLink) {
    return Promise.reject(new Error("Impossibile inviare: il link non è valido."));
  }

  // Aggiungi il link al messaggio sotto la scritta "Link"
  payload.message += `${payload.import_playlist_spotify}`;

  // Se il link è corretto, esegui la richiesta API
  return apiClient.post('contact-page', payload)
    .then(r => {
      console.log("Risultato della richiesta:", r.data);
      return r.data;
    })
    .catch(error => {
      console.error("Errore durante la richiesta:", error);
      if (error.response) {
        console.error("Dettagli dell'errore:", error.response.status, error.response.statusText, error.response.data);
      }
      throw error; // Rilancia l'errore per consentire al chiamante di gestirlo
    });
}

