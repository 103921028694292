import {Trans} from '@ui/i18n/trans';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {Form} from '@ui/forms/form';
import {useForm} from 'react-hook-form';
import {
  ImportSpotifyPlaylistPayload,
  useSubmitContactForm,
} from './use-submit-contact-playlistspot';
import {FormTextField} from '@ui/forms/input-field/text-field/text-field';
import {Button} from '@ui/buttons/button';
import {useRecaptcha} from '../recaptcha/use-recaptcha';
import {StaticPageTitle} from '../seo/static-page-title';
import {Footer} from '@common/ui/footer/footer';

export function ImportSpotifyPlaylist() {
  const form = useForm<ImportSpotifyPlaylistPayload>();
  const submitForm = useSubmitContactForm(form);
  const {verify, isVerifying} = useRecaptcha('contact');

  return (
    <div className="flex flex-col bg-alt min-h-screen">
      <StaticPageTitle>
        <Trans message="Import your Spotify Playlist" />
      </StaticPageTitle>
      <Navbar
        className="flex-shrink-0 sticky top-0"
        menuPosition="import-playlist"
      />
      <div className="container p-24 md:p-40 mx-auto flex-auto flex items-center justify-center">
        <div className="border rounded bg-paper p-24 max-w-620">
          <h1 className="text-2xl">
            <Trans message="Import Playlist" />
          </h1>
          <p className="text-sm mt-4 mb-30">
            <Trans message="The import process will be verified by the staff. Once we review the playlist we will attribute it to your account." />
          </p>
          <Form
            form={form}
            onSubmit={async value => {
              const isValid = await verify();
              if (isValid) {
                submitForm.mutate(value);
              }
            }}
          >
            <FormTextField
              label={<Trans message="Email" />}
              name="email"
              required
              type="email"
              className="mb-20"
            />
            <FormTextField
              label={<Trans message="Playlist link" />}
              name="import_playlist_spotify"
              required
              className="mb-20"
            />
            <Button
              type="submit"
              variant="flat"
              color="primary"
              disabled={submitForm.isPending || isVerifying}
            >
              <Trans message="Send" />
            </Button>
          </Form>
        </div>
      </div>
      <Footer className="container mx-auto px-24 flex-shrink-0" />
    </div>
  );
}
